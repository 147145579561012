.herocard{
    width: 120px;
    height: 160px;
    border-radius: 10px;
    opacity: 1;

    display: inline-block;
    background-color: #8f8f8f;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow: hidden;

    transition: all 0.3s;


    &.hide{
        opacity: 0;
    }

    img{
        position: absolute;
        width: 100%;
        height: auto;
        object-fit: contain;
        border-radius: 3px;
    }


    .name{
        position: absolute;
        top: 0;
        right: 0;

        width: 54px;
        height: 18px;
        line-height: 18px;
        border-radius: 0px 0px 0px 10px;
        text-align: center;
            
        background: #4EB587;
        color: #FFFFFF;

        font-size: 8px;
    }

    .datas{
        background-color: #fff;
        width: 100%;
        min-height: 45px;
        position: absolute;
        border-radius: 8px;
        bottom: 0;
        left: 0;

        display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 0 10px;
        box-sizing: border-box;

        .data_block{
            display: flex;
            flex-direction: column;
        }

        .data_block_value{
            font-size: 16px;
            color: #000;
        }

        .data_block_plus{
            font-size: 8px;
            color: #E42626;
        }
        .data_block_name{
            font-size: 8px;
            color: #898989;
        }
    }

    &.core{
        .name{
            background-color: #614EB5;
        }
    }

    &.equipment{
        .name{
            background-color: #B54E6B;
        }
    }




}
