#userPage{
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    background-position: top;
    background-attachment: fixed;


    header{
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        height: 64px;
        width: 100%;
        font-size: 24px;
        font-weight: bold;
        .icon{
            width: 12px;
            margin: 0 15px;
            display: flex;
            align-items: center;
            img{
                max-width: 100%;
                object-fit: contain;
                object-position: center;
            }
        }

   
        .compose_button{
            width: 48px;
            height: 48px;
            border-radius: 100%;
            box-sizing: border-box;
            border: 2px solid #FFFFFF;
            background-color: gray;
            box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.3);
            text-align: center;

            display: flex;
            
            flex-direction: column;
            justify-content: center;

            transition: all 0.3s;
            pointer-events: none;
            margin: 0 15px;

            .cn{
                font-size: 12px;
                color: #ccc;	
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            }

            .en{
                font-size: 8px;
                color: #3D3D3D;
            }


            &.ready{
                pointer-events: all;
                background: linear-gradient(170deg, #F150E1 10%, #44D3C5 95%);
                animation: breathing 5s ease-out infinite normal;
                .cn{
                    color: #fff;
                }
            }

        }
    }

    .logout{
        padding: 10px;
        .logout_button{
            color: #fff;
            font-size: 12px;
            display: inline-block;
            text-decoration: underline;
        }
    }

    .userInfos{
        display: flex;
        align-items: center;
        padding: 0 15px;

        .computility,.silicon{
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            margin: 0 10px;
            min-width: 45px;

            .name{
                font-size: 12px;
            }

            .value{
                font-size: 36px;
            }
        }

        .gap{
            flex: 1;
        }



    }

    .eventButtonWrap{
        position: relative;
        width: 83px;
        height: 83px;


        .eventButtonShadow{
            position: absolute;
            width: 80px;
            height: 80px;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 14px;
            left: 3px;
            top: 3px;
        }

        .eventButton{
            width: 80px;
            height: 80px;
            border-radius: 14px;
            background: rgba(0, 0, 0, 0.2);
            box-sizing: border-box;
            border: 1px solid #A4A4A4;
            // box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.3);
            position: absolute;
            overflow: hidden;

            text-align: center;

            &:before{
                content: " ";
                display: inline-block;
                width: 20px;
                height: 2px;
                border-radius: 1px;
                background-color: rgba(255, 255, 255, 0.9);
                position: absolute;
                top: 1px;
                right: 13px;
            }


            &:after{
                content: " ";
                display: inline-block;
                height: 10px;
                width: 2px;
                border-radius: 1px;
                background-color: rgba(255, 255, 255, 0.9);
                position: absolute;
                top: 10px;
                right: 8px;
            }

            img{
                width: 50px;
                height: 50px;
                object-fit: contain;
                margin-top: 3px;
            }
    
            .title{
                width: 100%;
                height: 24px;
                line-height: 24px;
                position: absolute;
                left: 0;
                bottom: 0;
                text-align: center;
                background-color: #fff;
                border-top: 1px solid #A4A4A4;
                font-size: 10px;
                color: #000000;
                text-shadow: 1px 1px 0px rgba(137, 137, 137, 0.3);
            }    
            &:active{
                top: 3px;
                left: 3px;
            }
        }

        .tag{
            min-width: 40px;
            height: 10px;
            border-radius: 3px;
            position: absolute;
            left: 0;
            top: -3px;
            font-size: 8px;
            text-align: center;
            background-color: #CD0A24;
            color: #fff;
            position: absolute;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
        }

        .flag{
            position: absolute;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAAA8CAYAAADha7EVAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAJySURBVHic7dxBbtNAFMbxbxwvmrDJESw17JsF+3KDHgFu0BugnABuUG7QI5Q1EHwBpPoI3ZFF42FBbQFpgj0znpGY/28VpXH0Fk/vkz0vNdvF+Y1k3ghIoCja/UayTepCkKdivWsaGW1SF4I8me7Ft/nqzhpdJqwFGSq6F8Y+vk1ZCPLUN+B61zQSUYy4zN9vbBfn95KpoleCLBUHb7T71ykKQZ4OGpAoRkwHEdwhihHDwQTs/0AUI4KjDbjeNY2sPsQsBvk52oCSVPx45JgOkzrZgGs1DxzTYUpHb0J+x8YMpnJyAvYfYmMGExnUgGzMYCqDIrhDFCO0QROw/3C730h6mKgWZGhUAz49GySKEcyoCO5wTIdQRk3A/iKO6RCIUwOyMYNQnCK4QxTDl9ME7C8miuHJqwGJYvjyiuAOUQxXXhOw07aGn3TCSZAGfLX7/kmyH0N8F/ISpAElNmbgJlgDsjEDF8EaUJKsZVEB4wS5C5ak+qyq2qKsJS1DfSf+f8EmoDXljWg+jBSkAbfz1TX/2g0uvCOY6IUP7wlI9MKHVwMSvfDlHMGfX7y8mFlbhywG+XGagLWq5cy2t6GLQX5Kl4vaeflOUhW2FORodAR/OVtdFoXupigG+RnVgLWqZbuY1ez+IZRREUz0IrTBE/Dr4vzKyHDjgaAGNeCv6C3vxQNnBDboMUy74LQD0/hnA27nq2tJVxFqQYZORjCLBpjayQnIogGmdrQBWTRADM9GMNGLWJ6dgPuivBXNhwgOTkK289V7SRcJakGG/ohgFg0QW9+ALBoghT6CWTRACkYiepGOIXqRUvm0aFClLgR5+gnSOaKXuiSFmAAAAABJRU5ErkJggg==');
            width: 80px;
            height: 30px;
            background-size: contain;
            text-align: center;
            line-height: 30px;
            color: #fff;
            font-size: 10px;
            right: 83px;
            top: 13px;
            filter: drop-shadow(3px 3px rgba(0, 0, 0, 0.3));
            padding: 0 0 0 5px;
            box-sizing: border-box;
            background-repeat: no-repeat;

            &:active{
                top: 16px;
                right: 80px;
                filter: none;
            }

        }
    }

    .heroTeam{
        padding:20px 5px 0 ;
        .title{
            color: #fff;
            font-size: 14px;
            margin-bottom: 8px;
            padding:0 5px;
        }

        .teamWrap{
            display: grid;
            grid-template-columns: 33.3% 33.4% 33.3%;
            justify-items:center;
            row-gap: 15px;

            .cardWrap{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .icon{
                margin-top: 10px;
            }

            .change_tip{
                color: #fff;
                font-size: 10px;
                text-decoration: underline;
            }

        }

        .placeholder{
            width: 120px;
            height: 160px;
            border-radius: 10px;
            opacity: 1;
            background: rgba(255, 255, 255, 0.3);
            border: 1px dashed #FFFFFF;
            box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.3);

            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 18px;

            &:active{
                box-shadow: inset 2px 6px 10px 0px rgba(0, 0, 0, 0.3);
            }

        }

        
    }

    .cardsLinks{
        position: absolute;
        bottom: 15px;
        padding: 0 15px;
        a{
            display: inline-block;
            width: 100px;
            margin-right: 15px;
            text-decoration: none;
            position: relative;


            img{
                width: 100%;
                height: auto;
                object-fit: contain;
            }

            em{
                font-style: normal;
                font-size: 10px;
                color: #F17575;
                width: 100%;
                text-align: center;
                position: absolute;
                top: 39px;
            }
        }
    }

    .eventLinks{
        position: absolute;
        right: 15px;
        bottom: 0;

        .eventButtonWrap{
            margin-bottom: 15px;
        }

    }


    .heroSelectModal{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .bg{
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            left: 0;
            top: 0;
            animation: scaleup 0.3s;
        }

        .cardsContainer{
            height: 50%;
            background-color: #4EB586;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            position: absolute;
            border-radius: 20px 20px 0px 0px;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
            animation: showup 0.3s;
            padding: 15px 5px;
            box-sizing: border-box;
            overflow: scroll;
        }

        .cards_wrap{
            display: grid;
            grid-template-columns: 33.3% 33.4% 33.3%;
            justify-items:center;
            row-gap: 15px;
        }


        .noHeros{
            text-align: center;
            color: #ddd;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            .emoji{
                font-size: 24px;
            }
            .tip{
                font-size: 10px;
                width: 100%;
                position: absolute;
                bottom: 15px;
                color: #fff;
            }


        }

    }


    @keyframes scaleup{
        0%{
           opacity: 0;
        }
        100%{
            opacity: 1;
        }

    }


    @keyframes showup{
        0%{
            transform: translateY(100%);
        }
        90%{
            transform: translateY(0%);
        }
        100%{
            transform: translateY(2%);
        }

    }






}