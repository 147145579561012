#lastestHerosPage{
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: top;
    background-color: #000;
    max-height: -webkit-fill-available;

    header{
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        background-color: #000;
        height: 64px;
        width: 100%;
        font-size: 24px;
        font-weight: bold;
        .icon{
            width: 12px;
            margin: 0 15px;
            display: flex;
            align-items: center;
            img{
                max-width: 100%;
                object-fit: contain;
                object-position: center;
            }
        }

   
        .compose_button{
            width: 48px;
            height: 48px;
            border-radius: 100%;
            box-sizing: border-box;
            border: 2px solid #FFFFFF;
            background-color: gray;
            box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.3);
            text-align: center;

            display: flex;
            
            flex-direction: column;
            justify-content: center;

            transition: all 0.3s;
            pointer-events: none;
            margin: 0 15px;

            .cn{
                font-size: 12px;
                color: #ccc;	
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
            }

            .en{
                font-size: 8px;
                color: #3D3D3D;
            }


            &.ready{
                pointer-events: all;
                background: linear-gradient(170deg, #F150E1 10%, #44D3C5 95%);
                animation: breathing 5s ease-out infinite normal;
                .cn{
                    color: #fff;
                }
            }

        }
    }

    .heroWrap{
        width: 160px;
        height: 160px;
        border-radius: 100%;
        position: absolute;

        animation: riseup linear 2s;

      .border{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: 1px solid #fff;
        border-radius: 100%;

        mask-image: -webkit-gradient(
            linear,  right top, left bottom,
            color-stop(0.00,  rgba(0,0,0,1)),
            color-stop(0.05,  rgba(0,0,0,1)),
            // color-stop(0.50,  rgba(0,0,0,0)),
            color-stop(0.65,  rgba(0,0,0,0.4)),
            color-stop(1.00,  rgba(0,0,0,0)));
      }

      .border2{
        position: absolute;
        width: 90%;
        height: 90%;
        left: 5%;
        top: 5%;
        border: 1px solid #fff;
        border-radius: 100%;

        mask-image: -webkit-gradient(
            linear,  right top, left bottom,
            color-stop(0.00,  rgba(0,0,0,1)),
            color-stop(0.1,  rgba(0,0,0,1)),
            color-stop(0.2,  rgba(0,0,0,0)),
            color-stop(1.00,  rgba(0,0,0,0)));
        }
      

        img{
            width: 100%;
            height: auto;
            object-fit: contain;
            mask-image: radial-gradient(circle, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 30%, rgba(0,0,0,0) 65% , rgba(0,0,0,0) 100%);
        }

        a{
            text-align: center;
            display: flex;
            text-decoration: none;
            flex-direction: column;
            align-items: center;
        }

        .name{
            display: inline-block;
            background-color: #fff;
            font-size: 8px;
            color: #333;
            text-decoration: none;
            height: 12px;
            line-height: 12px;
            padding: 0 3px;
            border-radius: 3px;
            min-width: 24px;
            margin-top: -20px;
        }
    }

    @keyframes riseup {
        0%{
            transform: translate(5px,500px);
        }
        20%{
            transform: translate(-5px,400px);
        }
        40%{
            transform: translate(5px,300px);
        }
        60%{
            transform: translate(-5px,200px);
        }
        80%{
            transform: translate(5px,100px);
        }
        100%{
            transform: translate(0,0);
        }
    }


}