#eventLandingPage{
    width: 100%;
    min-height: 100vh;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 20px;
    box-sizing: border-box;
    position: relative;

    h2.title{
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        position: sticky;
        font-size: 24px;
        font-weight: bold;
        .icon{
            width: 12px;
            margin: 0 15px;
            display: flex;
            align-items: center;
            img{
                max-width: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    .scripts_outer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .script_container{
        width: 90%;
        min-height: 210px;
        background-color: #88EDB9;
        border-radius: 10px;
        box-shadow: 3px 3px 0px 0px rgba(255, 255, 255, 0.3);
        margin-bottom: 30px;
        position: relative;

        .level{
            color: #066336;
            position: absolute;
            left: 10px;
            top: 10px;
            font-size:24px;
            font-weight: bold;
        }

        .infos{
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: end;
            box-sizing: border-box;
            padding: 10px;
            min-height: 210px;

            .title{
                font-size:14px;
                color: #000;
                padding: 0 10px;
                display: inline-block;            
                background: linear-gradient(180deg, rgba(244, 154, 154, 0) 0%, rgba(244, 154, 154, 0) 20%, #F49A9A 20%, #F49A9A 80%, rgba(244, 154, 154, 0) 80%, rgba(244, 154, 154, 0) 100%);
                margin-bottom: 10px;
                display: inline-block;
            }

            .hashrate{
                font-size: 12px;
                color: #4C4C4C;
                display: inline-block;
                b{
                    color: #262626;
                    font-size: 14px;
                }
            }
            .gap{
                flex: 1;
            }

            .awards{
                display: inline-block;
                .awards_title{
                    color: #4C4C4C;
                    font-size: 8px;
                    text-align: right;
                    margin-bottom: 3px;
                }
                .awards_content{
                    width: 138px;
                    border-radius: 2px;
                    background: #D7F9E7;
                    display: grid;
                    grid-template-columns: 33.3% 33.4% 33.3%;
                    row-gap: 5px;
                    box-sizing: border-box;
                    padding: 8px 0;
                    // min-height: 100px;

                    .awards_element{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: relative;
                        .place{
                            width: 40px;
                            height: 40px;
                            border: 1px dotted #C7C7C7;
                            margin-bottom: 3px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #066336;

                            img{
                                width: 100%;
                                height: auto;
                                object-fit: contain;
                                border-radius: 3px;
                            }
                        }

                        .awards_name{
                            font-size: 8px;
                            color: #3D3D3D;
                            overflow: hidden;
                            // text-overflow: ellipsis;
                            // white-space: nowrap;
                            text-align: center;
                        }

                        .tips{
                            font-size: 6px;
                            width: 26px;
                            position: absolute;
                            top: 2px;
                            background-color: rgba(233, 0, 0, 0.8);
                            color: #fff;
                            white-space: nowrap;
                            text-align:  center;
                        }

                        .rate{
                            position: absolute;
                            top: 30px;
                            font-size: 8px;
                            display: inline-block;
                            background-color: #fff;
                            border-radius: 2px;
                            padding: 0 3px;
                            // right: 0;
                        }
                    }
                }
            }

            .state_text{
                display: inline-block;
                margin-top: 10px;
                font-size: 8px;
                color: #000;
                display: flex;
                justify-content: center;
                align-items: center;
                .buttons{
                    display: flex;
                    justify-content: end;
                    width: 155px;

                    .gap{
                        flex: 1;
                    }

                    .start_game{
                        display: flex;
                        align-items: center;

                        .tag{
                            width: 64px;
                            height: 20px;
                            line-height: 20px;
                            border-radius: 5px 0px 0px 5px;
                            background: #F7F7F7;
                            font-size: 8px;
                            text-align: center;
                            margin-right: -3px;
                        }
                    }

                    .btn{
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        background: #F7F7F7;
                        box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.3);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #C7C7C7;
                    }
                }
            }

        }

        .heros{
            background-color: #F1EBB7;
            width: 150px;
            height: 150px;
            position: absolute;
            top: 36px;
            left: 36px;
            border-radius: 20px;

            .heroAvatar{
                position: absolute;
                overflow: hidden;
                border-radius: 10px;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
                img{
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                }

                &:nth-of-type(1){
                    width: 50px;
                    height: 50px;
                    transform: rotate(-15deg);
                    top: 35px;
                    left: -8px;
                }

                &:nth-of-type(2){
                    width: 70px;
                    height: 70px;
                    transform: rotate(-8deg);
                    top: -5px;
                    left: 25px;

                }

                &:nth-of-type(3){
                    width: 110px;
                    height: 110px;
                    transform: rotate(25deg);
                    top: 25px;
                    left: 48px;
                }
            }

            .heroBg{
                width: 100%;
                height: 100%;
                img{
                    width: 100%;
                    object-fit: contain;
                    border-radius: 20px;
                }
            }

        }

        .bridge{
            position: absolute;
            left: 50%;
            height: 30px;
            width: 3px;
            bottom: -30px;
            box-shadow: 3px 3px 0px 0px rgba(255, 255, 255, 0.3);

            &.unlock2lock{
                background: linear-gradient(180deg, #88EDB9 0%, #EBEBEB 100%);
            }

            &.unlock2unlock{
                background-color: #88EDB9;
            }

            &.lock2lock{
                background-color: #EBEBEB;
            }

        }


        &.unactive{

            background-color: #EBEBEB;

            .level{
                color: #5B5B5B;
            }

            .infos{
                .title{
                    color: #767676;
                    background: linear-gradient(180deg, rgba(244, 154, 154, 0) 0%, rgba(244, 154, 154, 0) 20%, #D3D3D3 20%, #D3D3D3 80%, rgba(244, 154, 154, 0) 80%, rgba(244, 154, 154, 0) 100%);
                }

                .hashrate{
                    color: #A7A7A7;
                    b{
                        color: #929292;
                    }
                }
            }
            .awards{
                .awards_title{
                    color: #8A8A8A;
                }

                .awards_content{
                    background-color: #E1E1E1;

                    .awards_element{
                        .place{
                            img{
                                filter: grayscale(1);
                            }
                        }
                    }
                }
            }

            .heros{
                background-color: #D4D4D0;
                .heroAvatar{
                    filter: grayscale(1);
                }
            }

        }





    }


    .awardResultOuter{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;
        

        .bg{
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.5);
        }

        .resultInner{
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;


            .resultWrap{
                width: 260px;
                min-height: 200px;
                border-radius: 10px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                background: linear-gradient(180deg, #FFFFFF 0%, #FCE493 1%, #FAF7DF 100%);


                .title{
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                    color: #fff;
                    text-shadow: 0 0 5px rgba(243, 66, 66, 0.8);
                    margin-bottom: 20px;
                }

                .award_item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 5px 0;
    
                    .name{
                        width: 40px;
                        font-size: 8px;
                        text-align: center;
                        color: #3D3D3D;


                        .img{
                            position: relative;
                            .tag{
                                width: 32px;
                                height: 12px;
                                position: absolute;
                                bottom: 4px;
                                border-radius: 3px;
                                background-color: #333;
                                color: #fff;
                                left: 4px;
                                font-size: 8px;
                                line-height: 12px;
                                text-align: center;
                            }

                            .rate{
                                position: absolute;
                                top: 2px;
                                right: 2px;
                                border-radius: 3px;
                                background-color: #fff;
                                font-size: 6px;
                                line-height: 12px;
                                min-width: 12px;
                            }

                        }
                        img{
                            width: 100%;
                            object-fit: contain;
                            border-radius: 3px;
                            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
                        }
                    }
    
                    .line{
                        width: 120px;	
                        border-top: 1px dashed #A2A2A2;
                        height: 1px;
                    }
    
                    .value{
                        width: 24px;
                        font-size: 10px;
                        color: #BF2828;
                        font-weight: bold;
                        text-align: right;
                    }

                    
                    &.equipment{
                        .name{
                            .img{
                                .tag{
                                    background-color: #B54E6B;       
                                }
                            }
                        }
                    }
                    &.core{
                        .name{
                            .img{
                                .tag{
                                    background-color: #614EB5;       
                                }
                            }
                        }
                    }
                    &.hero{
                        .name{
                            .img{
                                .tag{
                                    background-color: #4EB587;       
                                }
                            }
                        }
                    }
                }

                .gap{
                    flex: 1;
                }

                .btns{
                    display: flex;
                    justify-content: center;
                    .accept_button{
                        width: 80px;
                        height: 28px;	
                        background: linear-gradient(180deg, #FFFFFF 0%, #A9E78A 2%, #95C534 96%, #8CA72A 97%, #64A72A 97%);
                        color: #fff;
                        font-size: 14px;
                        font-weight: bold;
                        border-radius: 3px;
                        text-align: center;
                        line-height: 28px;
                    }
                }


            }



            
	

        }


    }



    

}