#hongpage{
    width: 100%;
    position: relative;
    height: 100vh;

    .bg{
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
    }


    .container{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        display: flex;
        flex-direction: column;

        align-items: center;

        .logo{
            width: 75%;
            height: 200px;
            margin-top: -40px;
            margin-bottom: -20px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .hero_image{
            width: 80%;
            position: relative;
            margin-bottom: 10px;
            filter: drop-shadow( 3px  0px 0px #fff) 
                    drop-shadow(-3px  0px 0px #fff)
                    drop-shadow( 0px  3px 0px #fff) 
                    drop-shadow( 0px -3px 0px #fff);
            .hero_image_container{
                width: 100%;
                height: auto;
            }
        }

        .hero_name{
            width: 80%;
            font-size: 22px;
            color: #00A0E9;
            text-align: left;
            font-weight: bold;
            position: relative;
            margin-bottom: 10px;

  
            .tag{
                display: inline-block;
                position: absolute;
                right: 0;
                top: 0;
                font-size: 16px;
                padding: 2px 5px;
                color: #fff;
                font-weight: bold;
                border-radius: 3px;
            }


            &.speed{
                color: #00A0E9;
                .tag{
                    background-color: #00A0E9;
                }
            }
            &.defense{
                color: #F3D13E;
                .tag{
                    background-color: #F3D13E;
                }
            }
            &.attack{
                color: #E4007F;
                .tag{
                    background-color: #E4007F;
                }
            }

        }

        .lives{
            display: flex;
            align-items: center;
            color: #F3D13E;
        }

        .hero_description{
            width: 80%;
            color: #fff;
            font-weight: bold;
            margin-bottom: 30px;
        }

        .preview_button{
            width: 40%;
            img{
                width: 100%;
            }
        }

    }


}