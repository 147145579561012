#loginPage{
    height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: -webkit-fill-available;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;


    .loginContent{
        width: 342px;
        height: 284px;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        .login_button{
            width: 80px;
            height: 32px;
            background: #1AAD19;
            box-sizing: border-box;
            border: 1px solid rgba(0, 0, 0, 0.02);
            position: relative;
            margin-top: 180px;
            img{
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: contain;
                animation: showandhide 2s infinite;
                &:nth-of-type(2){
                    animation: showandhide2 2s infinite;
                }
            }
            
        }

    }

    @keyframes showandhide {
        0% {
            opacity: 1;
        }
        79%{
            opacity: 1;
        }
        80%{
            opacity: 0;
        }
        89%{opacity: 0;}
        90%{
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes showandhide2 {
        0% {
            opacity: 0;
        }
        79%{
            opacity: 0;
        }
        80%{
            opacity: 1;
        }
        89%{opacity: 1;
            transform: translate(3px,0px);
        }
        90%{
            opacity: 0;
            transform: translate(0  ,0);
        }
        100% {
            opacity: 0;
        }
    }

}