#asPage{
    min-height: 100vh;
    position: relative;


    header{
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        background-color: #000;
        height: 64px;
        width: 100%;
        font-size: 24px;
        font-weight: bold;
        .icon{
            width: 12px;
            margin: 0 15px;
            display: flex;
            align-items: center;
            img{
                max-width: 100%;
                object-fit: contain;
                object-position: center;
            }
        }

        .scan{
            height: 32px;
            width: 32px;
            margin: 0 15px;
            img{
                max-width: 100%;
                object-fit: contain;
                object-position: center;
            }

        }

    }



    .blackHoleBg{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        height: 100vh;
        align-items: center;
        justify-content: center;
        filter: blur(3px);
    	background: #000;


        .blackhole, .blackhole:before, .blackhole:after {
            display: flex;
            border-radius: 50%;
            animation: anim 7s linear infinite;
        }
        
        @keyframes anim {
            0% {transform: rotate(0deg)}
            100% {transform: rotate(-360deg)}
        }
        
        .blackhole {
            width: 60vw;
            height: 60vw;
            box-shadow: 1vh 0 3vh 2vh #f50, 
               inset -2vh 0 3vh 4vh #f80,
                     -1vh -1vh 35vh 0 #f60;
        }
        
        .blackhole:before, .blackhole:after {
            content: '';
            background: #fff;
            position: relative;
          top: 6vh;
        }
        
        .blackhole:before {
          width: 3vmin;
          height: 3vmin;
          left: 40vmin;
          box-shadow: #f4eb42 0 0 2vh 2vh,
                      #f4eb42 2vh 4vh 2vh 0.3vh;
        }
        

    }

    .cardContainer{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }


    .card{
        width: 120px;
        height: 160px;
        border-radius: 10px;
        opacity: 1;
        margin-bottom: 80px;
        
        transform: scale(1.5);
    
        display: inline-block;
        background-color: #8f8f8f;
        border: 2px solid #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
        position: relative;
        overflow: hidden;
    
        transition: all 0.3s;
    
    
        &.hide{
            opacity: 0;
        }
    
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    
    
        .name{
            position: absolute;
            top: 0;
            right: 0;
    
            width: 70px;
            height: 26px;
            line-height: 26px;
            border-radius: 0px 0px 0px 10px;
            text-align: center;
                
            background: #4EB587;
            color: #FFFFFF;
    
            font-size: 10px;
        }
    
        .datas{
            background-color: #fff;
            width: 116px;
            min-height: 40px;
            position: absolute;
            border-radius: 10px;
            bottom: 2px;
            left: 2px;
    
            display: flex;
            justify-content: space-between;
            text-align: center;
            padding: 0 10px;
            box-sizing: border-box;
    
            .data_block{
                display: flex;
                flex-direction: column;
            }
    
            .data_block_value{
                font-size: 16px;
                color: #000;
            }
    
            .data_block_plus{
                font-size: 8px;
                color: #E42626;
            }
            .data_block_name{
                font-size: 8px;
                color: #898989;
            }
        }
    
        &.core{
            .name{
                background-color: #614EB5;
            }
        }
    
        &.equipment{
            .name{
                background-color: #B54E6B;
            }
        }
    }

    

    .accept_button{
        width: 100px;
        height: 32px;
        border-radius: 3px;	
        background: linear-gradient(180deg, #FFFFFF 0%, #FFBA9B 10%, #E27542 97%);
        line-height: 32px;
        text-align: center;
        color: #3A3A3A;
    }

    .home_button{
        width: 100px;
        height: 32px;
        border-radius: 3px;	
        // background: linear-gradient(180deg, #FFFFFF 0%, #0dc3f1 10%, #263edb 97%);
        line-height: 32px;
        text-align: center;
        color: #3A3A3A;
        display: inline-block;
        color: #fff;
        position: absolute;
        bottom: 20px;
    }

    .accept_tip{
        color: #fff;
    }


    
}